import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "../../Base/Constancs.css";
import api from '../../api/api';
import CatalogPageAstroknight from '../../components/Astroknight/CatalogView/CatalogPage';
import CatalogPageBlue from '../../components/Blue/CatalogView/CatalogPage';
import CatalogPageBlueDrevo from '../../components/BlueDrevo/CatalogView/CatalogPage';
import BotiqueLabel from "../../components/BotiqueLabel";
import CatalogPageBox from '../../components/Boxberry/CatalogView/CatalogPage';
import CatalogPageList from '../../components/List/CatalogView/CatalogPage';
import PaginationV2 from "../../components/PaginationV2";
import CatalogPageSimple from '../../components/Simple/CatalogView/CatalogPage';
import CatalogPageWhite from '../../components/White/CatalogView/CatalogPage';
import { useTelegram } from '../../components/useTelegram';
import SearchComponent from "../MarketPlace_v2/components/SearchComponent";
import catalogApi from "./api/api";

const StoreBasePage = () => {
    const { storeID } = useParams();

    const nav = useNavigate();

    const { user } = useTelegram();
    const [storeData, setStoreData] = useState({
        products: [],
        categories: [],
        subcategories: [],
        storeInfo: {},
        banners: []
    });

    const [status, setStatus] = useState({ state: "close" });
    const [template, setTemplate] = useState(0);
    const [showLabel, setShowLabel] = useState(false);
    const [cartCount, setCartCount] = useState(0);
    const [cartData, setCartData] = useState([]);

    const [page, setPageState] = useState(sessionStorage.getItem('current_page') ? parseInt(sessionStorage.getItem('current_page')) : 1);
    const setPage = (page) => { setPageState(page); sessionStorage.setItem('current_page', 1); };

    const [totalPage, setTotalPage] = useState(1);

    const [searchQuery, setSearchQuery] = useState(null);
    const [selectedCategory, setSelectedCategoryState] = useState(sessionStorage.getItem('current_category') && sessionStorage.getItem('current_category') !== 'null' ? decodeURI(sessionStorage.getItem('current_category')) : null);
    const setSelectedCategory = (category) => { setSelectedCategoryState(category); sessionStorage.setItem('current_category', encodeURIComponent(category)); };

    const [selectedSubCategory, setSelectedSubCategoryState] = useState(sessionStorage.getItem('current_subcategory') && sessionStorage.getItem('current_subcategory') !== 'null' ? decodeURIComponent(sessionStorage.getItem('current_subcategory')) : null);
    const setSelectedSubCategory = (subcategory) => { setSelectedSubCategoryState(subcategory); sessionStorage.setItem('current_subcategory', encodeURIComponent(subcategory)); };

    const [numProducts, setNumProducts] = useState(0);

    const tg = window.Telegram.WebApp;

    const setMainButtonOnClick = (callback) => {
        tg.MainButton.onClick(callback);
    };

    useEffect(() => {
        catalogApi.getStoreInfo(storeID).then((response) => {
            setStoreData(prev => ({
                ...prev,
                storeInfo: response.data.store_info,
                banners: response.data.banners.sort((a, b) => a.sort - b.sort),
                categories: response.data.categories,
            }));
            setShowLabel(response.data.show_label);
            localStorage.setItem('template_code', response.data.store_info.template_code);
            setTemplate(response.data.store_info.template_code);

            if (response.data.categories.length === 1) {
                catalogApi.getSubCategories(storeID, response.data.categories[0]).then((response) => {
                    setStoreData(prev => ({
                        ...prev,
                        subcategories: response.data,
                    }));
                });
            }

            catalogApi.getStoreProducts(storeID, page, selectedCategory, selectedSubCategory).then((productsResponse) => {
                if (selectedCategory) {
                    catalogApi.getSubCategories(storeID, selectedCategory).then((subCategoriesResponse) => {
                        setStoreData(prev => ({
                            ...prev,
                            products: productsResponse.data.products,
                            subcategories: subCategoriesResponse.data,
                        }));
                    });

                } else {
                    setStoreData(prev => ({
                        ...prev,
                        products: productsResponse.data.products,
                    }));
                }

                setTotalPage(productsResponse.data.total_pages);
                setStatus({ state: "open" });
            });
        });

        api.getCartCount(user?.id.toString(), storeID).then((r) => { setCartCount(r.data.total_quantity); setCartData(r.data?.cart_items ? r.data?.cart_items : []); });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeID]);

    useEffect(() => {
        tg.BackButton.hide();
        if (template === 7) {
            tg.MainButton.hide();
        }
        if (user?.id) {
            const tgUser = {
                user_id: user?.id,
                username: user?.username,
                chat_id: user?.id,
                store_id: storeID
            }
            axios.post("https://user-vault.thebotique.ru/api/v1/user", tgUser).catch((e) => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeID]);

    useEffect(() => {
        var btn_color = '#000000';
        var txt_color = '#ffffff';

        if (template === 1) {
            btn_color = '#ed1651';
        }

        if (cartCount > 0) {
            setMainButtonOnClick(() => { nav(`/store/${storeID}/cart`) });
            tg.MainButton.setParams({ text: `Оформить заказ (${cartCount})`, color: btn_color, text_color: txt_color, is_active: true, is_visible: true });
        } else {
            tg.MainButton.setParams({ text: 'В корзине пусто', color: btn_color, text_color: txt_color, is_active: false, is_visible: true });
        }
        if (template === 7) {
            tg.MainButton.hide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartCount, template]);

    const hanlderForCategories = (e) => {
        const value = e.target.textContent;
        setSelectedSubCategory(null);
        if (value === 'Все') {
            setSelectedCategory(null);
            catalogApi.getStoreProducts(storeID, 1, null, null, searchQuery).then((response) => {
                setStoreData(prev => ({
                    ...prev,
                    products: response.data.products,
                    subcategories: [],
                }));
                setPage(1);
                setTotalPage(response.data.total_pages);
            });
        } else {
            setSelectedCategory(value);
            catalogApi.getStoreProducts(storeID, 1, value, null, searchQuery).then((productsResponse) => {
                catalogApi.getSubCategories(storeID, value).then((subCategoriesResponse) => {
                    setStoreData(prev => ({
                        ...prev,
                        products: productsResponse.data.products,
                        subcategories: subCategoriesResponse.data,
                    }));
                });

                setPage(1);
                setTotalPage(productsResponse.data.total_pages);
            });
        }
    };

    const hanlderForSubCategories = (e) => {
        const value = e.target.textContent;
        setSelectedSubCategory(value);

        if (value === 'Все') {
            catalogApi.getStoreProducts(storeID, 1, null, null, searchQuery).then((response) => {
                setStoreData(prev => ({
                    ...prev,
                    products: response.data.products,
                }));

                setPage(1);
                setTotalPage(response.data.total_pages);
            });
        } else {
            catalogApi.getStoreProducts(storeID, 1, selectedCategory, value, searchQuery).then((response) => {
                setStoreData(prev => ({
                    ...prev,
                    products: response.data.products,
                }));

                setPage(1);
                setTotalPage(response.data.total_pages);
            });
        }
    };

    if (status.state === "close") {
        return (
            <div></div>
            // <div className={`${styles.loadingOverlay} ${styles.show}`}>
            //     <div className={styles.loaderContainer}>
            //         <div className={styles.loader}></div>
            //         {/* <img className={styles.centerImage} src={botq} alt="Center" /> */}
            //     </div>
            // </div>
        )
    }

    const handlePageChange = (newPage, update) => {
        setPage(newPage);

        var cat = selectedCategory;
        var subcat = selectedSubCategory;
        var query = null;

        if (!update) {
            query = searchQuery;
        }

        catalogApi.getStoreProducts(storeID, newPage, cat, subcat, query).then((response) => {
            setStoreData(prev => ({
                ...prev,
                products: [...prev.products, ...response.data.products],
            }));

            setTotalPage(response.data.total_pages);
            // window.scrollTo(0, 0);
        });
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
        catalogApi.getStoreProducts(storeID, 1, null, null, query).then((response) => {
            setStoreData(prev => ({
                ...prev,
                products: response.data.products,
            }));

            setNumProducts(response.data.products.length * response.data.total_pages);
            setTotalPage(response.data.total_pages);
        });
    };

    const handleClear = () => { setSearchQuery(null); setNumProducts(0); handlePageChange(1, true); };

    return (
        <div>
            {template === 5 ? (
                <CatalogPageWhite data={storeData} setCartCount={setCartCount} hanlderForCategories={hanlderForCategories} hanlderForSubCategories={hanlderForSubCategories} cartData={cartData} setCartData={setCartData} preSelectedCategory={selectedCategory} preSelectedSubCategory={selectedSubCategory} />
            ) : template === 1 ? (
                <CatalogPageBox data={storeData} hanlderForCategories={hanlderForCategories} hanlderForSubCategories={hanlderForSubCategories} preSelectedCategory={selectedCategory} preSelectedSubCategory={selectedSubCategory} />
            ) : template === 2 ? (
                <CatalogPageList data={storeData} setCartCount={setCartCount} hanlderForCategories={hanlderForCategories} hanlderForSubCategories={hanlderForSubCategories} cartData={cartData} setCartData={setCartData} preSelectedCategory={selectedCategory} preSelectedSubCategory={selectedSubCategory} />
            ) : template === 3 ? (
                <CatalogPageSimple data={storeData} setCartCount={setCartCount} hanlderForCategories={hanlderForCategories} hanlderForSubCategories={hanlderForSubCategories} cartData={cartData} setCartData={setCartData} preSelectedCategory={selectedCategory} preSelectedSubCategory={selectedSubCategory} />
            ) : template === 4 ? (
                <CatalogPageAstroknight data={storeData} setCartCount={setCartCount} hanlderForCategories={hanlderForCategories} hanlderForSubCategories={hanlderForSubCategories} cartData={cartData} setCartData={setCartData} preSelectedCategory={selectedCategory} preSelectedSubCategory={selectedSubCategory} />
            ) : template === 6 ? (
                <CatalogPageBlue data={storeData} setCartCount={setCartCount} hanlderForCategories={hanlderForCategories} hanlderForSubCategories={hanlderForSubCategories} cartData={cartData} setCartData={setCartData} preSelectedCategory={selectedCategory} preSelectedSubCategory={selectedSubCategory} />
            ) : template === 7 ? (
                <CatalogPageBlueDrevo data={storeData} setCartCount={setCartCount} hanlderForCategories={hanlderForCategories} hanlderForSubCategories={hanlderForSubCategories} cartData={cartData} setCartData={setCartData} preSelectedCategory={selectedCategory} preSelectedSubCategory={selectedSubCategory} />
            ) : (
                <CatalogPageSimple data={storeData} setCartCount={setCartCount} hanlderForCategories={hanlderForCategories} hanlderForSubCategories={hanlderForSubCategories} cartData={cartData} setCartData={setCartData} preSelectedCategory={selectedCategory} preSelectedSubCategory={selectedSubCategory} />
            )}
            {totalPage > 1 && <PaginationV2 totalPages={totalPage} currentPage={page} onPageChange={handlePageChange} />}
            {(totalPage > 1 | numProducts > 0) ? <SearchComponent numProducts={numProducts} doSearch={handleSearch} doClear={handleClear} /> : null}
            {showLabel && <BotiqueLabel />}
        </div>
    );
};

export default StoreBasePage;
